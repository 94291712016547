import React, { useContext, useEffect, useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

import scrollIt from "../helpers/scrollIt"
import posterImage from "../images/poster_image.jpg"
import { GlobalDispatchContext } from "./GlobalContextProvider"

const SplashScreen = props => {
  const { type, menuOpen } = props

  const dispatch = useContext(GlobalDispatchContext)
  // const state = useContext(GlobalStateContext)

  const [startAnimation, setStartAnimation] = useState(false)

  // const { wasSplashViewed } = state

  /*
  useEffect(() => {
    if (wasSplashViewed === true && type === "home") {
      console.log("c")
      if (typeof window !== "undefined") {
        let pageHeight = window.innerHeight
        setTimeout(() => {
          window.scrollTo(0, pageHeight)
          console.log("cc")
        }, 1000)
      }
    }
  }, [])
  */

  useEffect(() => {
    const img = new Image()
    img.onload = function () {
      setStartAnimation(true)
    }
    img.src = posterImage
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") return
    if (
      startAnimation === true &&
      // wasSplashViewed === false &&
      type === "home"
    ) {
      setTimeout(() => {
        dispatch({ type: "SET_SPLASH_VIEWED" })
        let pageHeight = window.innerHeight
        const scrollPos =
          window.scrollY ||
          window.scrollTop ||
          document.getElementsByTagName("html")[0].scrollTop
        if (scrollPos <= pageHeight) {
          scrollIt(document.querySelector(".main_header"), 400)
        }
      }, 2000)
    }
  }, [startAnimation])

  if (type === "home") {
    return (
      <div
        className={clsx("splash_screen", {
          play: startAnimation === true /* && wasSplashViewed === false */,
          blurred: menuOpen,
        })}
        style={{ height: "100vh" }}
      >
        <div
          className="splash_screen__image"
          style={{ backgroundImage: `url(${posterImage})` }}
        ></div>
      </div>
    )
  } else {
    return null
  }
}

SplashScreen.propTypes = {
  type: PropTypes.string.isRequired,
  menuOpen: PropTypes.bool.isRequired,
}

SplashScreen.defaultProps = {}

export default SplashScreen
