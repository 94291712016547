import { graphql, useStaticQuery } from "gatsby"

const WordpressSiteMetadata = () => {
  const { wordpressSiteMetadata } = useStaticQuery(graphql`
    query {
      wordpressSiteMetadata {
        home
        description
        name
        url
      }
    }
  `)
  return wordpressSiteMetadata
}

export default WordpressSiteMetadata
