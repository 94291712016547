import { graphql, useStaticQuery } from "gatsby"

const WordpressMenuItemsByName = () => {
  const { wordpressMenusMenusItems } = useStaticQuery(graphql`
    query getItems($menuName: String) {
      wordpressMenusMenusItems(name: { eq: $menuName }) {
        items {
          db_id
          title
          url
        }
      }
    }
  `)
  return wordpressMenusMenusItems
}
export default WordpressMenuItemsByName
