import React, { useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Link } from "gatsby"
import debounce from "lodash/debounce"

import AllTags from "../components/allTags"
import useWordpressSiteMetaDataQuery from "../data/wordpressSiteMetadata"
import useWordpressMenuItemsByName from "../data/wordpressMenuItemsByName"

const Header = props => {
  const { type, pathname, menuOpen, setMenuOpen, isBlurred } = props
  const wordpressSiteMetadata = useWordpressSiteMetaDataQuery()
  const wordpressMenusMenusItems = useWordpressMenuItemsByName()

  // resize respo menu
  useEffect(() => {
    if (typeof window === "undefined") return
    const handleResize = () => {
      const style = window.getComputedStyle(
        document.querySelectorAll("a.menu_hamburger")[0]
      )
      if (style.display === "none") {
        setMenuOpen(false)
      }
    }
    window.addEventListener("resize", handleResize)
  }, [])

  // active tag on scroll
  useEffect(() => {
    if (typeof window === "undefined") return
    const isInViewport = (el, offset = 0) => {
      if (typeof window === "undefined") return
      var top = el.offsetTop
      var left = el.offsetLeft
      var width = el.offsetWidth
      var height = el.offsetHeight

      while (el.offsetParent) {
        el = el.offsetParent
        top += el.offsetTop
        left += el.offsetLeft
      }

      return (
        top < window.pageYOffset + window.innerHeight + offset &&
        left < window.pageXOffset + window.innerWidth &&
        top + height - offset > window.pageYOffset &&
        left + width > window.pageXOffset
      )
    }
    const isTagInViewport = () => {
      const articles = document.querySelectorAll(".articles")
      if (articles.length > 0) {
        const mainHeaderLogo = document.querySelectorAll(".main_header__logo")
        const mainHeaderTags = document.querySelectorAll(".main_header__tags")
        if (mainHeaderTags.length > 0) {
          const mainHeader = document.querySelectorAll(".main_header")[0]
          const scrollPos =
            window.scrollY ||
            window.scrollTop ||
            document.getElementsByTagName("html")[0].scrollTop

          if (
            articles[0].querySelectorAll(".articles__item")[0].offsetTop >=
            scrollPos + mainHeader.offsetHeight + 1
          ) {
            mainHeaderLogo[0].classList.remove("main_header__logo--hidden")
            mainHeaderTags[0].classList.remove("main_header__tags--visible")
          } else {
            let lastVisible = null
            for (let i = 0; i < articles.length; i++) {
              if (isInViewport(articles[i], mainHeader.offsetHeight) === true) {
                lastVisible = articles[i]
                break
              }
            }
            mainHeaderLogo[0].classList.remove("main_header__logo--hidden")
            mainHeaderTags[0].classList.remove("main_header__tags--visible")
            if (lastVisible !== null) {
              mainHeaderLogo[0].classList.add("main_header__logo--hidden")
              mainHeaderTags[0].classList.add("main_header__tags--visible")
              const tagName = lastVisible.getAttribute("data-tag-name")
              const anchorsActive = mainHeaderTags[0].querySelectorAll(
                "a.btn--active"
              )
              for (let i = 0; i < anchorsActive.length; i++) {
                anchorsActive[i].classList.remove("btn--active")
              }
              const tagsAnchor = mainHeaderTags[0].querySelectorAll(
                'a[href="#' + tagName + '"]'
              )
              if (tagsAnchor.length > 0) {
                tagsAnchor[0].classList.add("btn--active")
              }
            }
          }
        }
      }
    }

    window.addEventListener("scroll", debounce(isTagInViewport, 10))
  }, [])

  //absolute menu on scroll
  useEffect(() => {
    if (typeof window === "undefined") return
    if (type === "home") {
      const mainEl = document.querySelectorAll("main")[0]
      const mainHeader = document.querySelectorAll(".main_header")[0]
      const shouldBeHeaderAbsolute = () => {
        const scrollPos =
          window.scrollY ||
          window.scrollTop ||
          document.getElementsByTagName("html")[0].scrollTop
        if (
          mainEl.offsetTop + mainHeader.offsetHeight <= scrollPos &&
          mainHeader.classList.contains("main_header--absolute")
        ) {
          mainHeader.classList.add("no-animate")
          mainHeader.classList.remove("main_header--absolute")
          setTimeout(() => {
            mainHeader.classList.remove("no-animate")
          }, 255)
        } else if (
          mainEl.offsetTop + mainHeader.offsetHeight > scrollPos &&
          !mainHeader.classList.contains("main_header--absolute")
        ) {
          mainHeader.classList.add("main_header--absolute")
        }
      }
      window.addEventListener("scroll", debounce(shouldBeHeaderAbsolute, 5))
    }
  }, [])

  return (
    <header
      className={clsx("main_header", {
        "main_header--opened": menuOpen,
        "main_header--absolute": type === "home",
      })}
    >
      <div className="inner">
        <h1
          className={clsx("main_header__logo", {
            blurred: menuOpen || isBlurred,
          })}
        >
          <Link to="/">
            <strong>{wordpressSiteMetadata.name}</strong>
            <br />
            {wordpressSiteMetadata.description}
          </Link>
        </h1>
        <a
          className={clsx("menu_hamburger", {
            animating: !menuOpen,
            "menu_hamburger--active": menuOpen,
          })}
          href="#"
          onClick={e => {
            e.preventDefault()
            setMenuOpen(!menuOpen)
          }}
        >
          <span className="menu_hamburger__dot menu_hamburger__dot--1" />
          <span className="menu_hamburger__dot menu_hamburger__dot--2" />
          <span className="menu_hamburger__dot menu_hamburger__dot--3" />
        </a>
        {wordpressMenusMenusItems.items.length > 0 && (
          <nav
            className={clsx("main_header__nav", {
              shown: menuOpen,
            })}
          >
            <ul>
              {wordpressMenusMenusItems.items.map(item => {
                const { db_id, title, url } = item
                return (
                  <li
                    key={db_id}
                    className={clsx({
                      "current-menu-item":
                        (type === "home" && url === "/") ||
                        url === pathname ||
                        (url === "/" && type === "single-post"),
                    })}
                  >
                    <Link to={url}>{title}</Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        )}
        {type === "home" && (
          <AllTags
            classes={clsx("articles__tags main_header__tags", {
              blurred: menuOpen,
            })}
          />
        )}
      </div>
    </header>
  )
}

Header.propTypes = {
  type: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  isBlurred: PropTypes.bool.isRequired,
}

Header.defaultProps = {}

export default Header
