import React, { useState, useEffect } from "react"
import IdleTimer from "react-idle-timer"
import ReactTypingEffect from "../vendor/react-typing-effect/src/lib"
import shuffle from "lodash/shuffle"

import useAllWordpressWPQuote from "../data/allWordpressWpQuote"

const ScreenSaver = props => {
  const allWordpressWpQuote = useAllWordpressWPQuote()

  const { edges: quotesNodes } = allWordpressWpQuote
  const [isIdle, setIsIdle] = useState(false)
  const [quotes, setQuotes] = useState([])

  let quotesExtracted = quotesNodes.map(({ node }) => {
    const { title } = node
    return title
  })

  useEffect(() => {
    setQuotes(shuffle(quotesExtracted))
  }, [isIdle])

  return (
    <>
      <IdleTimer
        onActive={() => {
          if (isIdle === true) {
            setIsIdle(false)
          }
        }}
        onIdle={() => {
          setIsIdle(true)
        }}
        debounce={250}
        timeout={1000 * 45}
      />
      {isIdle && (
        <div className="screensaver">
          <p>
            <ReactTypingEffect
              text={quotes}
              speed={125}
              typingDelay={1000}
              eraseDelay={2500}
            />
          </p>
        </div>
      )}
    </>
  )
}

export default ScreenSaver
