import { graphql, useStaticQuery } from "gatsby"

const AllWordpressTag = () => {
  const { allWordpressTag } = useStaticQuery(graphql`
    query {
      allWordpressTag(sort: { order: ASC, fields: wordpress_id }) {
        edges {
          node {
            id
            name
            slug
            count
          }
        }
      }
    }
  `)
  return allWordpressTag
}

export default AllWordpressTag
