import React, { useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import { TransitionState } from "gatsby-plugin-transition-link"

import Header from "./header"
import SplashScreen from "./SplashScreen"
import ScreenSaver from "./ScreenSaver"
// import Popup from "./Popup"
import footerLogo from "../images/5R Logo.svg"

const Layout = props => {
  const { type, children, template, pathname, isBlurred } = props

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <ScreenSaver />
      <SplashScreen type={type} menuOpen={menuOpen} />
      <Header
        type={type}
        pathname={pathname}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        isBlurred={isBlurred}
      />

      <main className={clsx({ blurred: menuOpen })}>{children}</main>

      {type !== "single-post" && (
        <footer className="main_footer">
          <div className="inner">
            <ul className="three_cols">
              <li>
                <a
                  href="https://5rightsfoundation.com/"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  <img
                    className="main_footer__logo"
                    src={footerLogo}
                    alt="5RIGHTS FOUNDATION"
                    width="139"
                    height="29"
                  />
                </a>
              </li>
              <li>
                <p className="text-left">
                  <a
                    href="https://5rightsfoundation.com/"
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    Visit website
                  </a>
                  <br />
                  <a
                    href="https://twitter.com/5rightsfound"
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    Follow on Twitter
                  </a>
                </p>
              </li>
              <li>
                <p className="text-right">
                  A project by 5Rights Foundation
                  <br />
                  Copyright © 5Rights Foundation, {new Date().getFullYear()}
                </p>
              </li>
            </ul>
          </div>
        </footer>
      )}

      {/* <Popup type={type} /> */}

      <TransitionState>
        {({ mount, transitionStatus }) => {
          if (transitionStatus !== "entered") {
            return (
              <TransitionPortal>
                <div
                  style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100vw",
                    height: "100vh",
                    background: "#f5f5f5",
                  }}
                ></div>
              </TransitionPortal>
            )
          }
        }}
      </TransitionState>

      <Helmet>
        <link rel="stylesheet" href={"/css/styles.css"} media="screen" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={"/images/favicons/apple-icon-57x57.png"}
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={"/images/favicons/apple-icon-60x60.png"}
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={"/images/favicons/apple-icon-72x72.png"}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={"/images/favicons/apple-icon-76x76.png"}
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={"/images/favicons/apple-icon-114x114.png"}
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={"/images/favicons/apple-icon-120x120.png"}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={"/images/favicons/apple-icon-144x144.png"}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={"/images/favicons/apple-icon-152x152.png"}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={"/images/favicons/apple-icon-180x180.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={"/images/favicons/android-icon-192x192.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={"/images/favicons/favicon-32x32.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={"/images/favicons/favicon-96x96.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={"/images/favicons/favicon-16x16.png"}
        />
        <link
          href={
            "https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap"
          }
          rel="stylesheet"
        />
        <link rel="manifest" href={"/images/favicons/manifest.json"} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content={"/images/favicons/ms-icon-144x144.png"}
        />
        <meta name="theme-color" content="#ffffff" />
        <link rel="stylesheet" href={"/css/print.css"} media="print" />
        <script src={"/js/manifest.js"} defer></script>
        <script src={"/js/vendor.js"} defer></script>
        {template === "page-about.php" && (
          <script src={"/js/three.js"} defer></script>
        )}
        <script src={"/js/main.js"} defer></script>
      </Helmet>
    </>
  )
}

Layout.propTypes = {
  type: PropTypes.string,
  template: PropTypes.string,
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string,
  isBlurred: PropTypes.bool,
}

Layout.defaultProps = {
  type: "default",
  template: "",
  pathname: "",
  isBlurred: false,
}

export default Layout
