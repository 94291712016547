import { graphql, useStaticQuery } from "gatsby"

const AllWordpressWPQuote = () => {
  const { allWordpressWpQuote } = useStaticQuery(graphql`
    query {
      allWordpressWpQuote {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  `)
  return allWordpressWpQuote
}

export default AllWordpressWPQuote
