import React, { useState, useEffect } from "react"
import clsx from "clsx"
import ReactHtmlParser from "react-html-parser"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import debounce from "lodash/debounce"

const SEO = ({ lang, title, bodyClasses, yoast_head }) => {
  const [scrollDirection, setScrollDirection] = useState("")

  useEffect(() => {
    if (typeof window === "undefined") return

    const header = document.querySelectorAll(".main_header")[0]
    let lastScroll = 0
    let localScrollDirection = ""
    const scrollUp = "scroll-up"
    const scrollDown = "scroll-down"
    const showHeaderOnScroll = () => {
      const currentScroll = window.pageYOffset
      if (currentScroll === 0) {
        setScrollDirection("")
        return
      }
      if (
        currentScroll > lastScroll &&
        localScrollDirection !== scrollDown &&
        currentScroll > header.offsetHeight
      ) {
        localScrollDirection = scrollDown
      } else if (
        currentScroll < lastScroll &&
        localScrollDirection === scrollDown
      ) {
        localScrollDirection = scrollUp
      }
      lastScroll = currentScroll
      setScrollDirection(localScrollDirection)
    }
    if (bodyClasses === "home") {
      setTimeout(() => {
        window.addEventListener("scroll", debounce(showHeaderOnScroll, 5))
      }, 2500)
    } else {
      window.addEventListener("scroll", debounce(showHeaderOnScroll, 5))
    }
  }, [])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      bodyAttributes={{
        class: clsx(scrollDirection, bodyClasses),
      }}
    >
      {ReactHtmlParser(yoast_head)}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  title: ``,
  bodyClasses: ``,
  yoast_head: ``,
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  bodyClasses: PropTypes.string,
  yoast_head: PropTypes.string,
}

export default SEO
